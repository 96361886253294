import React, { useState, useEffect } from "react";
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import UpworkImage from "../../images/UpworkHeroSection.png"; // Path to the uploaded image

const HeroSection = () => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks form submission state
  const [feedbackMessage, setFeedbackMessage] = useState(""); // Stores success/error messages

  const quotes = [
    "Accelerate Growth with Precision-Driven Lead Generation",
    "Leading Solution for Business Success",
    "Your Quality Marketing Assistant Success",
    "Unlock Growth with Targeted Lead Strategies",
    "Maximize Revenue with Expert Lead Generation",
  ];

  // Quote animation logic
  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setIsVisible(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [quotes.length]);

  // Form handling logic
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start submission process

    // EmailJS service configuration
    emailjs.send(
      'service_k4a44zf',  // Your EmailJS service ID
      'template_z7slh1s',  // Your EmailJS template ID
      formData,  // The form data you want to send
      'iMgvQMQ4m81-tW9lt'  // Your EmailJS public user ID
    )
    .then((result) => {
      console.log('Email sent successfully!', result.text);
      setFeedbackMessage("Your message has been sent successfully!"); // Success message
      setFormData({ firstName: '', email: '', message: '' }); // Reset form
    })
    .catch((error) => {
      console.log('Error sending email:', error.text);
      setFeedbackMessage("There was an error sending your message. Please try again."); // Error message
    })
    .finally(() => {
      setIsSubmitting(false); // Stop submission process
    });
  };

  return (
    <div
      className="relative flex flex-col md:flex-row justify-between items-center min-h-screen text-center bg-cover bg-center bg-no-repeat px-8 bg-[#374d6e] overflow-hidden"
      style={{ backgroundImage: "url(/path/to/your/background-image.jpg)" }}
    >
      {/* Left Section */}
      <div className="flex-1 flex flex-col justify-center items-center md:items-start text-center md:text-left px-8 mt-12 md:mt-20 md:px-12 lg:px-24 h-full">
        <h1
          className={`text-3xl md:text-4xl lg:text-5xl font-bold text-[#3b566e] leading-tight text-white mt-6 ml-6 transition-opacity duration-500 ease-in-out ${isVisible ? "opacity-100" : "opacity-0"}`}
        >
          {quotes[currentQuoteIndex].split(" ").slice(0, 4).join(" ")}
          <br />
          <span style={{ color: "#40f8ff" }}>
            {quotes[currentQuoteIndex].split(" ").slice(4).join(" ")}
          </span>
        </h1>
        <p className="text-large text-[#3b566e] font-bold md:text-base lg:text-lg mb-4 text-white mt-4 ml-6">
          Fuel your pipeline so you can focus on driving growth. <br />
        </p>
        <a
          href="https://www.upwork.com/agencies/425274009047953408/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-[#1a73e8] hover:bg-[#004d99] text-white font-bold py-2 px-8 rounded-md mb-4 ml-6">
            Hire Us!
          </button>
        </a>
      </div>

      {/* Right Section - Form */}
      <div className="flex-1 flex justify-center items-center mr-0 md:mr-20 h-full px-4 md:px-4 lg:px-4 mt-0 md:mt-16 mb-4 md:mb-0">
        <div className="flex-1 flex justify-center items-center h-full">
          <div className="bg-[#3b566e] p-6 rounded-3xl shadow-lg max-w-lg w-full text-left relative bg-gradient-to-l from-[#1b2e4c] to-[#3e5880] shadow-xl">
            {/* Add a white shadow using custom classes */}
            <div className="absolute inset-0 rounded-3xl bg-transparent shadow-[0_0_15px_5px_rgba(255,255,255,0.3)]"></div>

            {/* Form content */}
            <div className="relative z-10">
              <h2 className="text-white font-bold text-2xl mb-4">
                Let’s start our cooperation with
              </h2>
              <h3 className="text-[#40f8ff] font-bold text-xl mb-4">
                FREE TEST WORK!
              </h3>

              <form onSubmit={handleSubmit}>
                {/* First Name */}
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Name"
                  className="w-full p-3 mb-4 border border-gray-300 text-white bg-transparent rounded-2xl focus:outline-none focus:border-blue-400 placeholder-gray-400"
                  required
                />

                {/* Business Email */}
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Business E-mail"
                  className="w-full p-3 mb-4 border border-gray-300 text-white bg-transparent rounded-2xl focus:outline-none focus:border-blue-400 placeholder-gray-400"
                  required
                />

                {/* Message */}
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Message"
                  className="w-full p-3 mb-4 border border-gray-300 text-white bg-transparent rounded-2xl focus:outline-none focus:border-blue-400 placeholder-gray-400 h-32 resize-none"
                  required
                />

                {/* Submit Button */}
                <button
                  type="submit"
                  className="bg-[#1a73e8] hover:bg-[#004d99] text-white font-bold py-3 px-6 rounded-2xl w-full"
                  disabled={isSubmitting} // Disable button while submitting
                >
                  {isSubmitting ? 'Sending...' : 'Free Test'}
                </button>
              </form>

              {/* Feedback message */}
              {feedbackMessage && (
                <p className="mt-4 text-white text-center">
                  {feedbackMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
