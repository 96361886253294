import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post('http://localhost:8000/backend/api/users/login', credentials);
      
  //     // Save authentication status or token in localStorage
  //     localStorage.setItem('authToken', response.data.token); // Assuming the token is returned
  //     localStorage.setItem('userEmail', response.data.email);
  //     // Set success message and navigate
  //     setMessage('Sign-in successful! Redirecting...');
  //     setTimeout(() => {
  //       navigate('/addjob');
  //     }, 2000); // Delay for user to see success message
  //   } catch (error) {
  //     console.error(error);
  //     // Set error message and clear any success message
  //     setMessage('Invalid email or password. Please try again.');
  //   }
  // };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.eprimesoftsolutions.com/nodejsserver/users/login', credentials);
      
      console.log('API Response:', response.data); // Log the response data
  
      // Save authentication status or token in localStorage
      localStorage.setItem('authToken', response.data.token); // Assuming the token is returned
      localStorage.setItem('Email', response.data.email);
  
      // Set success message and navigate
      setMessage('Sign-in successful! Redirecting...');
      setTimeout(() => {
        navigate('/addjob');
      }, 2000); // Delay for user to see success message
    } catch (error) {
      console.error('Error during sign-in:', error);
      // Set error message and clear any success message
      setMessage('Invalid email or password. Please try again.');
    }
  };
  

  return (
    <section className="bg-[#f0f4f8] py-12 min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 rounded-lg shadow-md border border-gray-200 max-w-md mx-auto">
          <h2 className="text-3xl font-bold text-[#17315c] mb-6 text-center">Sign In</h2>
          {message && (
            <div
              className={`mb-4 p-4 border rounded-lg ${message.includes('successful') ? 'bg-green-100 text-green-700 border-green-300' : 'bg-red-100 text-red-700 border-red-300'}`}
            >
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-[#38a0d9] text-white px-6 py-3 rounded-lg hover:bg-[#1f5ea8] transition-colors duration-300 w-full"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
