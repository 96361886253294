import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/adminJobForm/Modal';

const AdminJobForm = () => {
  const [job, setJob] = useState({
    title: '',
    location: '',
    description: '',
    requirements: [],
  });
  const [jobs, setJobs] = useState([]);
  const [Email, setUserEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteJobId, setDeleteJobId] = useState(null);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const navigate = useNavigate();

  const removeRequirement = (index) => {
        const newRequirements = job.requirements.filter((_, i) => i !== index);
        setJob((prevJob) => ({ ...prevJob, requirements: newRequirements }));
      };


  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://www.eprimesoftsolutions.com/nodejsserver/job/', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        });
        setJobs(response.data);
      } catch (error) {
        setMessage('Error fetching jobs.');
        setMessageType('error');
      }
    };

    const fetchUserEmail = () => {
      const email = localStorage.getItem('Email');
      if (email) {
        setUserEmail(email);
      } else {
        navigate('/signin'); // Redirect if no user email is found
      }
    };

    fetchJobs();
    fetchUserEmail();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob((prevJob) => ({ ...prevJob, [name]: value }));
  };

  const handleRequirementChange = (e, index) => {
    const newRequirements = [...job.requirements];
    newRequirements[index] = e.target.value;
    setJob((prevJob) => ({ ...prevJob, requirements: newRequirements }));
  };

  const addRequirement = () => {
    setJob((prevJob) => ({ ...prevJob, requirements: [...prevJob.requirements, ''] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://www.eprimesoftsolutions.com/nodejsserver/job/', job, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        }
      });
      setJob({
        title: '',
        location: '',
        description: '',
        requirements: [],
      });
      const response = await axios.get('https://www.eprimesoftsolutions.com/nodejsserver/job/', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        }
      });
      setJobs(response.data);
      setIsSuccessOpen(true);
      setTimeout(() => setIsSuccessOpen(false), 2000); // Close popup after 2 seconds
    } catch (error) {
      setMessage('An error occurred while adding the job.');
      setMessageType('error');
    }
  };

  const handleEdit = (jobId) => {
    navigate(`/editjob/${jobId}`); // Navigate to the edit page for this job
  };

  const handleDelete = (jobId) => {
    setDeleteJobId(jobId);
    setIsConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (deleteJobId) {
      try {
        await axios.delete(`https://www.eprimesoftsolutions.com/nodejsserver/job/${deleteJobId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        });
        setMessage('Job deleted successfully!');
        setMessageType('success');
        const response = await axios.get('https://www.eprimesoftsolutions.com/nodejsserver/job/', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        });
        setJobs(response.data);
      } catch (error) {
        setMessage('An error occurred while deleting the job.');
        setMessageType('error');
      }
      setIsConfirmOpen(false);
      setDeleteJobId(null);
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('Email');
    navigate('/signin');
  };

  return (
    <section className="bg-[#f0f4f8] py-12 min-h-screen">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 rounded-lg shadow-md border border-gray-200 max-w-2xl mx-auto">
          <div className="mb-6">
            <p className="text-lg font-semibold text-[#333] mb-2">
              Signed in as: {Email}
              <span className='px-4'>
                <button
                  onClick={handleSignOut}
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-colors duration-300"
                >
                  Sign Out
                </button>
              </span>
            </p>
          </div>
          <h2 className="text-3xl font-bold text-[#17315c] mb-6">Add New Job</h2>
          
          {isSuccessOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center z-40"
            >
              <div className="bg-green-500 text-white p-4 rounded-lg shadow-lg max-w-sm w-full">
                <p>Job added successfully!</p>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="title">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={job.title}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="location">
                Location
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={job.location}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={job.description}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                rows="4"
                required
              />
            </div>
            <div className="mb-6">
             <label className="block text-lg font-semibold text-[#333] mb-2">Requirements</label>
               {job.requirements.map((req, index) => (
                 <div key={index} className="flex items-center mb-2">
                   <input
                     type="text"
                    value={req}
                    onChange={(e) => handleRequirementChange(e, index)}
                    className="w-full p-4 border border-gray-300 rounded-lg mr-2 focus:outline-none focus:border-[#38a0d9]"
                    placeholder={`Requirement ${index + 1}`}
                  />
                  <button
                    type="button"
                    onClick={() => removeRequirement(index)}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-300"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addRequirement}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
              >
                Add Requirement
              </button>
            </div>
            <button
              type="submit"
               className="bg-[#38a0d9] text-white px-6 py-3 rounded-lg hover:bg-[#1f5ea8] transition-colors duration-300 w-full">
              Add Job
            </button>
          </form>
        </div>

        <div className="mt-12">
  <h2 className="text-3xl font-semibold text-[#17315c] mb-6">Job List</h2>
  <div className="overflow-x-auto">
    <table className="w-full bg-white border border-gray-300 rounded-lg shadow-lg">
      <thead className="bg-gray-100 border-b border-gray-300">
        <tr>
          <th className="p-4 text-left text-sm font-medium text-gray-700">Title</th>
          <th className="p-4 text-left text-sm font-medium text-gray-700">Description</th>
          <th className="p-4 text-left text-sm font-medium text-gray-700">Actions</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <tr key={job._id} className="hover:bg-gray-50 transition-colors duration-300">
            <td className="p-4 border-b text-sm text-gray-600">{job.title}</td>
            <td className="p-4 border-b text-sm text-gray-600">{job.description}</td>
            <td className="p-4 border-b flex space-x-2">
              <button
                onClick={() => handleEdit(job._id)}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(job._id)}
                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-colors duration-300"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

      </div>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this job?"
      />
    </section>
  );
};

export default AdminJobForm;
