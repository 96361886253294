import React from "react";
import styles from "./MovingTools.module.css";

// Import local images
import LinkedInLogo from './../../images/LinkedInLogo.png';
import Name2EmailLogo from './../../images/Name2EmailLogo.png';
import SnovioLogo from './../../images/SnovioLogo.png';
import HunterLogo from './../../images/HunterLogo.png';
import ClearbitLogo from './../../images/ClearbitLogo.png';
import SalesQLLogo from './../../images/SalesQLLogo.svg';
import RocketReachLogo from './../../images/RocketReachLogo.svg';
import GemLogo from './../../images/GemLogo.svg';
import FacebookLogo from './../../images/FacebookLogo.svg';
import KendoLogo from './../../images/KendoLogo.png';
import InstagramLogo from './../../images/InstagramLogo.png';
import hubsupport from './../../images/hubsupot.svg'
import saleforce from './../../images/salesforce.svg'
import neverbounce from './../../images/neverbounce.png'
import slack from './../../images/slack.png'
import veriflia from './../../images/verifalia.png'

// Define the tools with imported logos
const tools = [
  
  { name: "name2email", logo: Name2EmailLogo },
  { name: "Snov.io", logo: SnovioLogo },
  { name: "Hunter", logo: HunterLogo },
  { name: "Clearbit", logo: ClearbitLogo },
  { name: "SalesQL", logo: SalesQLLogo },
  { name: "RocketReach", logo: RocketReachLogo },
  // { name: "Gem", logo: GemLogo },
  // { name: "Facebook", logo: FacebookLogo },
  // hubsupport,saleforce,slack,neverbounce,veriflia,
  { name: "Kendo", logo: KendoLogo },
  // { name: "Instagram", logo: InstagramLogo },
  { name: "hubsupport", logo: hubsupport },
  { name: "saleforce", logo: saleforce },
  { name: "neverbounce", logo: neverbounce },
  { name: "LinkedIn", logo: LinkedInLogo },
  { name: "slack", logo: slack },
  { name: "veriflia", logo: veriflia },
];

const MovingTools = () => {
  const toolList = [...tools, ...tools]; // Duplicate for infinite scrolling

  return (
    <div className={styles.movingToolsSection}>
      <h2 className={styles.sectionHeading}>Tools and Technologies We Use</h2>
      <div className={styles.movingToolsContainer}>
        <div className={styles.movingTools}>
          {toolList.map((tool, index) => (
            <div className={styles.toolItem} key={index}>
              <img src={tool.logo} alt={tool.name} className={styles.toolLogo} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MovingTools;
