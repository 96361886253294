import React, { useEffect } from 'react';
import bgimg from '../images/homeimg.jpeg';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import SuccessThroughTheYears from '../components/home/SuccessThroughTheYears';
import ClientTestimonialsCarousel from '../components/services/ClientTestimonialsCarousel';
import OurServices from '../components/home/OurServices';
import ExplainServices from '../components/home/ExplainServices';
import MovingTools from '../components/movingtools/MovingTools';
import FounderWords from '../components/home/FounderWords';


const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Background image section with blur effect */}
      <div
        className="absolute inset-0 -z-10 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
      >
        {/* Color overlay */}
        <div
          className="absolute inset-0"
          style={{
            backgroundColor: '#294d7b',
            opacity: 0.5,
            mixBlendMode: 'multiply',
          }}
        ></div>
      </div>
      <div className="relative z-10 bg-[#eef2f6]">
        <HeroSection />
        <FounderWords/>
        <OurServices/>
        {/* <FeaturesSection /> */}
        <SuccessThroughTheYears />
        <MovingTools/>
        {/* <ExplainServices/> */}
        {/* Client Testimonials */}
        <ClientTestimonialsCarousel/>
      
      </div>
    </>
  );
};

export default HomePage;
