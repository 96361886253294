// import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';

// const Career = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const jobListings = [
//     {
//       title: 'Lead Generation Specialist',
//       location: 'Remote',
//       description: 'We are looking for a Lead Generation Specialist who will be responsible for generating leads and nurturing client relationships.',
//       requirements: [
//         'Proven experience in lead generation.',
//         'Familiarity with LinkedIn, ZoomInfo, and other lead-gen tools.',
//         'Strong communication skills.',
//       ],
//     },
//     {
//       title: 'Data Entry Specialist',
//       location: 'Remote',
//       description: 'We are seeking a Data Entry Specialist to manage and maintain accurate company records.',
//       requirements: [
//         'Proficiency in Excel and Google Sheets.',
//         'Attention to detail.',
//         'Ability to meet deadlines.',
//       ],
//     },
//     {
//       title: 'Web Research Expert',
//       location: 'Remote',
//       description: 'We are looking for a Web Research Expert to provide detailed and accurate web research services for our clients.',
//       requirements: [
//         'Experience in web research.',
//         'Proficiency with web scraping tools.',
//         'Strong analytical skills.',
//       ],
//     },
//   ];

//   return (
//     <section className="bg-[#f0f4f8] pt-24 pb-12 min-h-screen">
//       <div className="container mx-auto px-4">
//         <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Join Our Team</h2>
//         <p className="text-lg text-center text-[#555] mb-8">
//           We are always looking for talented professionals to join our team. If you're passionate about lead generation, data analysis, web research, or virtual assistant services, check out our open positions below.
//         </p>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {jobListings.map((job, index) => (
//             <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
//               <h3 className="text-2xl font-semibold text-[#333] mb-2">{job.title}</h3>
//               <p className="text-[#777] mb-4">{job.location}</p>
//               <p className="text-[#555] mb-4">{job.description}</p>
//               <h4 className="text-lg font-bold text-[#333] mb-2">Requirements:</h4>
//               <ul className="list-disc list-inside text-[#555] mb-4">
//                 {job.requirements.map((requirement, idx) => (
//                   <li key={idx}>{requirement}</li>
//                 ))}
//               </ul>
//               <Link
//                 to="/applynow"
//                 className="inline-block bg-[#1a73e8] text-white px-4 py-2 rounded hover:bg-[#004d99] transition-colors duration-300"
//               >
//                 Apply Now
//               </Link>
//             </div>
//           ))}
//         </div>

//         <div className="text-center mt-12">
//           <h3 className="text-3xl font-bold text-[#333] mb-4">Don’t see the right job?</h3>
//           <p className="text-lg text-[#555] mb-6">We’re always looking for great talent. Feel free to send us your resume and tell us how you can make a difference!</p>
//           <Link
//             to="/contact"
//             className="inline-block bg-[#1a73e8] text-white px-6 py-3 rounded hover:bg-[#004d99] transition-colors duration-300"
//           >
//             Contact Us
//           </Link>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Career;



// below code will be used after connecting to backend
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Career = () => {
  const [jobListings, setJobListings] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://www.eprimesoftsolutions.com/nodejsserver/job');
        setJobListings(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchJobs();
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-[#f0f4f8] pt-24 pb-12 min-h-screen">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Join Our Team</h2>
        <p className="text-lg text-center text-[#555] mb-12">
          We are always looking for talented professionals to join our team. If you're passionate about lead generation, data analysis, web research, or virtual assistant services, check out our open positions below.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {jobListings.map((job, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-2xl font-semibold text-[#333] mb-2">{job.title}</h3>
              <p className="text-[#777] mb-4">{job.location}</p>
              <p className="text-[#555] mb-4">{job.description}</p>
              <h4 className="text-lg font-bold text-[#333] mb-2">Requirements:</h4>
              <ul className="list-disc list-inside text-[#555] mb-4">
                {job.requirements.map((requirement, idx) => (
                  <li key={idx}>{requirement}</li>
                ))}
              </ul>
              <Link
                to="/applynow"
                className="inline-block bg-[#1a73e8] text-white px-4 py-2 rounded hover:bg-[#004d99] transition-colors duration-300"
              >
                Apply Now
              </Link>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <h3 className="text-3xl font-bold text-[#333] mb-4">Don’t see the right job?</h3>
          <p className="text-lg text-[#555] mb-6">We’re always looking for great talent. Feel free to send us your resume and tell us how you can make a difference!</p>
          <Link
            to="/contact"
            className="inline-block bg-[#38a0d9] text-white px-6 py-3 rounded hover:bg-[#1f5ea8] transition-colors duration-300"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Career;
