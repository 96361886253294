import React, { useRef, useEffect } from 'react';
import axios from 'axios';

const ApplyNow = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    
    try {
      const response = await axios.post('/api/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      alert('Application sent successfully!');
    } catch (error) {
      console.error(error);
      alert('An error occurred, please try again.');
    }

    e.target.reset();
  };

  return (
    <section className="bg-[#f0f4f8] py-8 md:py-12 flex flex-col items-center min-h-screen mt-11">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0">
          {/* Heading Section */}
          <div className="md:w-1/2 text-center md:text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-[#17315c] mb-4">Join Our Team!</h2>
            <p className="text-lg md:text-xl text-[#777] mb-8">
              We're always on the lookout for talented individuals. Apply now!
            </p>
          </div>
          {/* Apply Now Form */}
          <div className="md:w-1/2">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="bg-white p-6 shadow-md rounded-lg border border-gray-200"
            >
              {/* Full Name and Email in the same row */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="user_name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                    required
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="user_email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="user_email"
                    name="user_email"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                    required
                  />
                </div>
              </div>
              {/* Cover Letter */}
              <div className="mb-4">
                <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="message">
                  Cover Letter
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                  required
                />
              </div>
              {/* Upload Resume */}
              <div className="mb-4">
                <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="resume">
                  Upload Resume
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-[#38a0d9] hover:bg-[#1f5ea8] text-white font-semibold py-2 px-6 rounded-lg transition-colors duration-300"
                >
                  Submit Application
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplyNow;
