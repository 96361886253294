import React, { useEffect } from 'react';

const projects = [
  {
    title: 'Fortune 1000 VPs and Directors Mining',
    description: 'Leads Generation, Spreadsheet, Data Mining.',
    tools: ['Leads Generation', 'Spreadsheet', 'Data Mining'],
  },
  {
    title: 'Finding Property Owner and Skip Tracing',
    description: 'Podio, Web Research, Data Entry, Excel.',
    tools: ['Podio', 'Web Research', 'Data Entry', 'Excel'],
  },
  {
    title: 'Researching B2B/B2C contacts',
    description: 'Lead Generation, Salesforce Management.',
    tools: ['Lead Generation', 'Salesforce Management'],
  },
  {
    title: 'Help with Web Research, Lead Generation, Data Entry, and more!',
    description: 'LinkedIn Leads Generation, Internet Research, Salesforce Data Entry.',
    tools: ['LinkedIn', 'Internet Research', 'Salesforce'],
  },
  {
    title: 'Lawn Care Lead Scraping',
    description: 'Yellowpages, Yelp, B2B, Web Research.',
    tools: ['Yellowpages', 'Yelp', 'B2B', 'Web Research'],
  },
  {
    title: 'Lead Generation List Building',
    description: 'LinkedIn, Data.com, Excel.',
    tools: ['LinkedIn', 'Data.com', 'Excel'],
  },
  {
    title: 'Finding contact info for cannabis businesses and investors in the USA',
    description: 'LinkedIn, Web Research, Spreadsheet Management.',
    tools: ['LinkedIn', 'Web Research', 'Spreadsheet'],
  },
  {
    title: 'Search companies from Zoominfo and put contact information in spreadsheet',
    description: 'Zoominfo, Data Entry, Web Research.',
    tools: ['Zoominfo', 'Data Entry', 'Web Research'],
  },
];

const Projects = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-[#f0f4f8] py-12 mt-12"> {/* mt-16 ensures it renders below the navbar */}
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Our Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 hover:bg-[#e0e7f1] transition duration-300 hover:shadow-lg transition-shadow duration-300 transform hover:scale-105 transition-transform duration-300"
            >
              <h3 className="text-2xl font-semibold text-[#333] mb-2">{project.title}</h3>
              <p className="text-[#777] mb-4">{project.description}</p>
              <ul className="list-disc list-inside">
                {project.tools.map((tool, toolIndex) => (
                  <li key={toolIndex} className="text-[#555]">{tool}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
